@tailwind base;
@tailwind components;
@tailwind utilities;

  @keyframes marquee {
    0% { transform: translateX(100%); }
    100% { transform: translateX(-100%); }
  }
  
  .animate-marquee {
    display: inline-block;
    animation: marquee 20s linear infinite;
  }
  